import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angular/core";
import { ElementRef, ViewChild } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/app/project/main/build/src/app/page.main/view.scss */
.video-container-portrait {
  width: auto;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 54/86;
}

.video-container-landscape {
  width: auto;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 86/54;
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(-1);
  height: 100%;
  object-fit: cover;
}`],
})
export class PageMainComponent implements OnInit {
    @ViewChild('video') video: ElementRef;
    @ViewChild('canvas') canvas: ElementRef;
    @ViewChild('capturedImage') capturedImage: ElementRef;

    public mode: any = 'landscape';
    public view: any = 'home';
    public data: any;
    public socket: any;
    public imageCapture: any;
    public request_id: any;
    public countdown: number = -1;
    public shortcuts: any;
    public previous_timestamp: number = 0;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();

        this.socket = wiz.socket();
        this.socket.on("connect", async () => {
            let uri = "request/test"
            await wiz.call(uri);
        });
        this.socket.on("disconnect", async () => { });
        this.socket.on("status", async (data: any) => {
            if (this.request_id == data.request_id) {
                if (data.value && data.max) {
                    await this.service.status.toggle('loading_text', `${data.info._meta.title}.. ${Math.round(data.value / data.max * 100)}%`);
                } else {
                    await this.service.status.toggle('loading_text', data.info._meta.title);
                }
            }
            await this.service.render();
        });

        await this.bindShortcut();

        const video = this.video.nativeElement;
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(async (stream) => {
                video.srcObject = stream;
                const track = stream.getVideoTracks()[0];
                this.imageCapture = new ImageCapture(track);
                await this.imageCapture.takePhoto();
            }).catch(err => { });
    }

    public async home() {
        this.view = 'home';
        await this.service.render();
    }

    public async setView(view: string) {
        this.view = view;
        await this.service.render();
    }

    public isRunning: boolean = false;

    public async next() {
        if (this.service.modal.isshow) {
            await this.service.modal.action();
            return;
        }

        if (this.isRunning) return;
        this.isRunning = true;

        if (this.view == 'start') {
            await this.viewCamera();
        } else if (this.view == 'camera') {
            await this.capture();
        } else if (this.view == 'captured') {
            let res = await this.service.modal.success("이 사진을 사용해서 그림을 그리시려면 버튼을 눌러주세요.", "취소하려면 버튼 2번!", "생성");
            if (res) {
                await this.transform();
            }
        } else if (this.view == 'result') {
            let res = await this.service.modal.success("이 그림을 프린트하려면 버튼을 눌러주세요.", "취소하려면 버튼 2번!", "프린트");
            if (res) {
                await this.printPicture();
            }
        }

        this.isRunning = false;
    }

    public async prev() {
        if (this.service.modal.isshow) {
            await this.service.modal.hide();
            return;
        }

        if (this.isRunning) return;
        this.isRunning = true;

        if (this.view == 'start') {
            await this.viewCamera();
        } else if (this.view == 'camera') {
            await this.capture();
        } else if (this.view == 'captured') {
            let res = await this.service.modal.success("정말 다시 촬영하시나요?", "취소하려면 버튼 2번!", "다시 촬영");
            if (res) {
                await this.viewCamera();
            }
        } else if (this.view == 'result') {
            let res = await this.service.modal.success("정말 다시 생성하시나요?", "취소", "다시 생성");
            if (res) {
                await this.capture(this.data.blob);
            }
        }
        this.isRunning = false;
    }

    public async request(fd, blob) {
        await this.service.status.toggle('loading_text', "Uploading...");
        await this.service.status.show('is_drawing');
        await this.service.status.show('loading');

        this.request_id = new Date().getTime();
        fd.append('request_id', this.request_id);

        let fdurl = wiz.url("upload");
        const { code, data } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => {
            await this.service.status.toggle('loading_text', `Uploading... ${percent}%`);
        });

        if (code == 200) {
            data.blob = blob;
            this.data = data;
            this.view = 'result';
        } else {
            this.service.modal.error("오류가 발생했습니다");
        }

        await this.service.status.hide('loading_text');
        await this.service.status.hide('loading');
        await this.service.status.hide('is_drawing');
        await this.service.render();
    }

    public async upload() {
        let files = await this.service.file.select({ accept: 'image/*' });
        if (files.length == 0) return;
        let fd = new FormData();
        fd.append('upload', files[0]);
        let blob = new Blob([files[0]], { type: files[0].type });
        await this.request(fd, blob);
        await this.service.render();
    }

    public async viewCamera() {
        await this.service.status.show('loading');
        for (let i = 0; i < 10; i++) {
            try {
                await this.imageCapture.takePhoto();
                break;
            } catch (e) {
            }
        }

        this.view = 'camera';
        await this.service.status.hide('loading');
        await this.service.render();
        this.data = {};
    }

    public async capture(blob: any) {
        if (!blob) {
            this.countdown = 1;
            for (let i = 0; i < 5; i++) {
                await this.service.render(1000);
                this.countdown = this.countdown + 1;
            }
            this.countdown = this.countdown + 1;
            await this.service.render();
            blob = await this.imageCapture.takePhoto();
        }
        this.view = 'captured';
        await this.service.render();
        await this.service.status.show('loading');
        if (this.mode == 'portrait') {
            blob = await this.cropImageToRatio(blob, 54, 86);
        } else {
            blob = await this.cropImageToRatio(blob, 86, 54);
        }
        this.data.blob = blob;
        const url = URL.createObjectURL(blob);
        await this.service.render();
        this.capturedImage.nativeElement.src = url;
        await this.service.status.hide('loading');
        this.countdown = -1;
        await this.service.render();
    }

    public async transform() {
        let blob = this.data.blob;
        const fd = new FormData();
        fd.append('upload', blob, 'captured_image.png');
        await this.request(fd, blob);
    }

    public async printPicture() {
        let uri = "request/" + this.data.target_id + '/' + this.data.files[0]
        const { data } = await wiz.call(uri);
        let res = await this.service.modal.success("그림을 인쇄중입니다", null, "확인");
        if (res)
            await this.setView("start");
    }

    public url(filename) {
        return wiz.url("result/" + filename);
    }

    public qrurl(filename) {
        return wiz.url("qrcode/" + filename);
    }

    public download(filename) {
        return wiz.url("download/" + filename);
    }

    public cropImageToRatio(blob: Blob, targetWidth: number, targetHeight: number): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const targetRatio = targetWidth / targetHeight;

            img.onload = () => {
                const originalWidth = img.width;
                const originalHeight = img.height;
                const originalRatio = originalWidth / originalHeight;

                let cropWidth, cropHeight, offsetX, offsetY;

                if (originalRatio > targetRatio) {
                    cropHeight = originalHeight;
                    cropWidth = cropHeight * targetRatio;
                    offsetX = (originalWidth - cropWidth) / 2;
                    offsetY = 0;
                } else {
                    // 원본이 더 높은 경우
                    cropWidth = originalWidth;
                    cropHeight = cropWidth / targetRatio;
                    offsetX = 0;
                    offsetY = (originalHeight - cropHeight) / 2;
                }

                const canvas = document.createElement('canvas');
                canvas.width = cropWidth;
                canvas.height = cropHeight;
                const ctx = canvas.getContext('2d');

                ctx.drawImage(
                    img,
                    offsetX, offsetY, cropWidth, cropHeight, // 원본에서 잘라낼 영역
                    0, 0, cropWidth, cropHeight // 캔버스에 그릴 영역
                );

                canvas.toBlob((croppedBlob) => {
                    if (croppedBlob) {
                        resolve(croppedBlob);
                    } else {
                        reject(new Error('Crop failed'));
                    }
                }, 'image/jpeg');
            };

            img.onerror = (err) => {
                reject(err);
            };

            img.src = URL.createObjectURL(blob);
        });
    }

    public cropImageToRatioBackup(blob: Blob, targetWidth: number, targetHeight: number): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const targetRatio = targetWidth / targetHeight;

            img.onload = () => {
                const originalWidth = img.width;
                const originalHeight = img.height;
                const originalRatio = originalWidth / originalHeight;

                let cropWidth, cropHeight;

                if (originalRatio > targetRatio) {
                    cropHeight = originalHeight;
                    cropWidth = cropHeight * targetRatio;
                } else {
                    cropWidth = originalWidth;
                    cropHeight = cropWidth / targetRatio;
                }

                const canvas = document.createElement('canvas');
                canvas.width = cropWidth;
                canvas.height = cropHeight;
                const ctx = canvas.getContext('2d');

                ctx.drawImage(
                    img,
                    (originalWidth - cropWidth) / 2,
                    (originalHeight - cropHeight) / 2,
                    cropWidth,
                    cropHeight,
                    0,
                    0,
                    cropWidth,
                    cropHeight
                );

                canvas.toBlob((croppedBlob) => {
                    if (croppedBlob) {
                        resolve(croppedBlob);
                    } else {
                        reject(new Error('Crop failed'));
                    }
                }, 'image/jpeg');
            };

            img.onerror = (err) => {
                reject(err);
            };

            img.src = URL.createObjectURL(blob);
        });
    }

    public async bindShortcut() {
        this.shortcuts = [];
        this.shortcuts.push({
            key: ["enter"],
            preventDefault: true,
            command: async () => {
                await this.setView("start");
            }
        });

        this.shortcuts.push({
            key: ["tab", "PageDown", "PageUp"],
            preventDefault: true,
            command: async () => {
                let DELAY: number = 1000;
                let timestamp: number = new Date().getTime();
                let delay: number = timestamp - this.previous_timestamp;
                this.previous_timestamp = timestamp;

                await this.service.sleep(DELAY);

                if (timestamp != this.previous_timestamp) {
                    return;
                }
                if (delay < DELAY) {
                    await this.prev();
                } else {
                    await this.next();
                }
            }
        });

        // this.shortcuts.push({
        //     key: ["PageDown"],
        //     preventDefault: true,
        //     command: async () => {
        //         await this.next();
        //     }
        // });

        // this.shortcuts.push({
        //     key: ["PageUp"],
        //     preventDefault: true,
        //     command: async () => {
        //         await this.prev();
        //     }
        // });

        await this.service.render();
    }

}

export default PageMainComponent;